<template>
  <form class="pb-2" action="." method="POST" @submit.prevent="submit">
    <div class="subtitle has-text-weight-bold">
      {{ $t("edit_profile.personal_details") }}
    </div>
    <div class="columns is-centered mt-1">
      <div class="column is-half">
        <!-- First name field -->
        <div class="field">
          <label class="label has-text-primary">{{ $t("edit_profile.edit_profile_first_name_hint") }}</label>
          <div class="control">
            <input class="input is-simple" type="text" :placeholder="$t('edit_profile.edit_profile_first_name_hint')" v-model="firstName" />
          </div>
          <template v-if="submitted">
            <p v-if="!$v.firstName.required" class="help is-danger">{{ $t("auth.error_first_name") }}</p>
          </template>
        </div>

        <!-- Position field -->
        <div class="field">
          <label class="label has-text-primary">{{ $t("edit_profile.edit_profile_position_hint") }}</label>
          <div class="control">
            <input class="input is-simple" type="text" :placeholder="$t('edit_profile.edit_profile_position_hint')" v-model="position" />
          </div>
        </div>
      </div>
      <div class="column is-half">
        <!-- Last name field -->
        <div class="field">
          <label class="label has-text-primary">{{ $t("edit_profile.edit_profile_last_name_hint") }}</label>
          <div class="control">
            <input class="input is-simple" type="text" :placeholder="$t('edit_profile.edit_profile_last_name_hint')" v-model="lastName" />
          </div>
          <template v-if="submitted">
            <p v-if="!$v.lastName.required" class="help is-danger">{{ $t("auth.error_last_name") }}</p>
          </template>
        </div>

        <!-- Company field -->
        <div class="field">
          <label class="label has-text-primary">{{ $t("edit_profile.edit_profile_my_company_hint") }}</label>
          <div class="control">
            <input class="input is-simple" type="text" :placeholder="$t('edit_profile.edit_profile_my_company_hint')" v-model="company" />
          </div>
        </div>
      </div>
    </div>

    <!-- Countries field -->
    <countries-autocomplete class="mb-2" v-model="country"></countries-autocomplete>

    <div class="subtitle has-text-weight-bold">
      {{ $t("edit_profile.edit_profile_biography_hint") }}
    </div>

    <!-- Biography field -->
    <div class="field mt-1">
      <div class="control">
        <textarea class="textarea is-simple" :placeholder="$t('edit_profile.edit_profile_biography_hint')" v-model="biography" />
      </div>
    </div>
    <div class="is-flex is-flex-direction-row mt-3">
      <div class="subtitle has-text-weight-bold">
        {{ $t("edit_profile.contact_details") }}
      </div>

      <!-- Show contact details field -->
      <div class="field ml-2">
        <b-switch size="is-small" v-model="showContactDetails"> {{ $t("edit_profile.show_contact_details") }}</b-switch>
      </div>
    </div>

    <!-- Phone number field -->
    <div class="field mt-2">
      <label class="label has-text-primary">{{ $t("edit_profile.edit_profile_phone_number_hint") }}</label>
      <div class="control">
        <input class="input is-simple" type="tel" :placeholder="$t('edit_profile.edit_profile_phone_number_hint')" v-model="phoneNumber" />
      </div>
    </div>

    <div class="subtitle has-text-weight-bold mt-3">
      {{ $t("edit_profile.my_goals") }}
    </div>

    <div class="columns is-centered">
      <div class="column is-half">
        <!-- What I Offer field -->
        <div class="field mt-1">
          <label class="label has-text-primary">{{ $t("edit_profile.what_i_offer") }}</label>
          <div class="control">
            <input class="input is-simple" type="tel" :placeholder="$t('edit_profile.what_i_offer')" v-model="whatIOffer" />
          </div>
        </div>
      </div>
      <div class="column is-half">
        <!-- What I Need field -->
        <div class="field mt-1">
          <label class="label has-text-primary">{{ $t("edit_profile.what_i_need") }}</label>
          <div class="control">
            <input class="input is-simple" type="tel" :placeholder="$t('edit_profile.what_i_need')" v-model="whatINeed" />
          </div>
        </div>
      </div>
    </div>

    <div class="subtitle has-text-weight-bold mt-3">
      {{ $t("edit_profile.connect_your_social_media") }}
    </div>

    <div class="columns is-centered">
      <div class="column is-half">
        <!-- Facebook field -->
        <div class="field mt-1">
          <label class="label has-text-primary">{{ $t("edit_profile.facebook_profile_hint") }}</label>
          <div class="control">
            <input class="input is-simple" type="tel" :placeholder="$t('edit_profile.facebook_profile_hint')" v-model="facebookUrl" />
          </div>
        </div>

        <!-- Twitter field -->
        <div class="field mt-1">
          <label class="label has-text-primary">{{ $t("edit_profile.twitter_profile_hint") }}</label>
          <div class="control">
            <input class="input is-simple" type="tel" :placeholder="$t('edit_profile.twitter_profile_hint')" v-model="twitterUrl" />
          </div>
        </div>

        <!-- YouTube field -->
        <div class="field mt-1">
          <label class="label has-text-primary">{{ $t("edit_profile.youtube_profile_hint") }}</label>
          <div class="control">
            <input class="input is-simple" type="tel" :placeholder="$t('edit_profile.youtube_profile_hint')" v-model="youtubeUrl" />
          </div>
        </div>
      </div>
      <div class="column is-half">
        <!-- LinkedIn field -->
        <div class="field mt-1">
          <label class="label has-text-primary">{{ $t("edit_profile.linkedin_profile_hint") }}</label>
          <div class="control">
            <input class="input is-simple" type="tel" :placeholder="$t('edit_profile.linkedin_profile_hint')" v-model="linkedInUrl" />
          </div>
        </div>

        <!-- Instagram field -->
        <div class="field mt-1">
          <label class="label has-text-primary">{{ $t("edit_profile.instagram_profile_hint") }}</label>
          <div class="control">
            <input class="input is-simple" type="tel" :placeholder="$t('edit_profile.instagram_profile_hint')" v-model="instagramUrl" />
          </div>
        </div>
      </div>
    </div>

    <!-- Api error -->
    <div v-if="error" class="mt-2 is-size-4 has-text-weight-bold has-text-danger">
      <div class="has-text-centered">
        {{ error | pluckError }}
      </div>
    </div>

    <!-- Submit button -->
    <div class="field">
      <div class="control">
        <button class="button is-primary is-fullwidth" type="submit" :disabled="showProgress">
          {{ $t("edit_profile.save_capital_letters") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import CountriesAutocomplete from "@/web/components/profile/CountriesAutocomplete";

export default {
  name: "EditProfileForm",
  components: { CountriesAutocomplete },
  props: ["value", "showProgress", "error"],

  data() {
    return {
      submitted: false,
      firstName: "",
      lastName: "",
      position: "",
      company: "",
      country: "",
      biography: "",
      showContactDetails: false,
      phoneNumber: "",
      whatIOffer: "",
      whatINeed: "",
      facebookUrl: "",
      linkedInUrl: "",
      twitterUrl: "",
      instagramUrl: "",
      youtubeUrl: "",
    };
  },

  validations: {
    firstName: { required },
    lastName: { required },
  },

  methods: {
    submit() {
      this.$v.$touch();
      this.$data.submitted = true;

      if (!this.$v.$invalid) {
        const formData = this.$data;
        this.$emit("submit", formData);
      }
    },
  },

  watch: {
    value: {
      handler(value) {
        // Copy only known properties in $data
        // eslint-disable-next-line prettier/prettier
        Object.keys(this.$data).forEach(
          key => (this.$data[key] = valueOrDefault(value && value[key]))
        );
      },
      immediate: true,
    },
  },
};

function valueOrDefault(value, defaultValue = null) {
  return typeof value === "undefined" ? defaultValue : value;
}
</script>
